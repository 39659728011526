import React from 'react';

function PoczatekButton() {
    return (
        <div>
            <footer>
                <a href="#banner" className="button">START</a>
            </footer>
        </div>
    );
}

export default PoczatekButton;