import React from 'react';
import ModalImage from 'react-modal-image';
import './Dorsz.css';

function Pstrag() {
  return (
    <div className="glowna">
      <h4>PSTRĄG</h4>

      <ModalImage
        small="./galeria/pstrag1_250.jpg"
        large="./galeria/pstrag1_1000.jpg"
        alt="psrąg"
      />

      <p>
        Pstrąg - mianem tym zwyczajowo określa się kilka gatunków ryb
        drapieżnych z rodziny łososiowatych, zasiedlających słodkie wody o
        bystrym prądzie, zwłaszcza górne odcinki rzek. W Polsce występuje:
        pstrąg potokowy (Salmo trutta m. fario) – słodkowodna odmiana troci
        atlantyckiej, pstrąg tęczowy (Oncorhynchus mykiss) – gatunek obejmujący
        formę wędrowną i słodkowodną, oraz słodkowodny pstrąg źródlany
        (Salvelinus fontinalis). Pstrąg tęczowy jest gatunkiem najczęściej
        hodowanym w celach konsumpcyjnych. Mięso pstrąga jest smaczne, ma zwartą
        konsystencję, podobną do mięsa dzikiego łososia. Filety są barwy od
        jasnoróżowej, poprzez różową, aż po czerwoną — stopień wybarwienia
        zależy od sposobu karmienia ryb. Pstrąg jest rybą bardzo wartościową
        żywieniowo. Jeśli chodzi o ilość łatwostrawnego białka (prawie 19 g w
        100 g mięsa), ustępuje jedynie sandaczowi. Jest to oczywiście białko
        bogate w niezbędne egzogenne aminokwasy takie jak lizyna, izoleucyna,
        metionina i cysteina. Mimo nie najniższej zawartości tłuszczu (nieco
        poniżej 7,5%), pstrągi cechują się dość niska wartością energetyczną,
        oscylującą w okolicy 140 kcal/100 g. Trzeba jednocześnie podkreślić, że
        tłuszcz zawarty w pstrągu jest wyjątkowo zasobny w kwasy tłuszczowe z
        rodziny ɷ3 – pod względem ilości kwasów EPA i DHA pstrąg ustępuje
        jedynie łososiowi bałtyckiemu (nie mylić z hodowlanym!) i szprotowi. Już
        nieco mniej niż 28 g pstrąga dostarcza nam dawkę kwasów EPA i DHA,
        rekomendowaną w profilaktyce chorób serca. W pstrągu znajdziemy też
        witaminę E i D3, przy czym ostatniej z nich jest aż 8 µg/100 g, co
        przekracza rekomendowaną jeszcze niedawno dawkę dzienną (obecnie zaleca
        się wyższe dawki ze względu na powszechność niedoborów tej witaminy).
        Pstrągi stanowią też źródło minerałów takich jak m. in.: fosfor, selen,
        czy potas.
      </p>

      <div className="obrazki">
        <ModalImage
          className="ramka"
          small="./galeria/pstrag2_250.jpg"
          large="./galeria/pstrag2_1000.jpg"
          alt="pstrąg - filet"
        />

        <ModalImage
          className="ramka"
          small="./galeria/pstrag3_250.jpg"
          large="./galeria/pstrag3_1000.jpg"
          alt="smażony pstrąg"
        />

        <ModalImage
          className="ramka"
          small="./galeria/pstrag4_250.jpg"
          large="./galeria/pstrag4_1000.jpg"
          alt="smażony pstrąg"
        />
      </div>
    </div>
  );
}

export default Pstrag;
