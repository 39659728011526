import React from 'react';
import ModalImage from 'react-modal-image';
import './Dorsz.css';

function Wegorz() {
  return (
    <div className="glowna">
      <h4>WĘGORZ</h4>

      <ModalImage
        small="./galeria/wegorz1_250.jpg"
        large="./galeria/wegorz1_1000.jpg"
        alt="węgorz"
      />

      <p>
        Węgorz (Anguilla anguilla) to gatunek ryby z rodziny węgorzowatych,
        występujący w rzekach i jeziorach Europy Zachodniej i Środkowej, w
        południowo-wschodniej i środkowej części Oceanu Atlantyckiego i we
        wszystkich morzach europejskich, w tym w Bałtyku. Mięso węgorza jest
        uważane za przysmak i rarytas, jedyny w swoim rodzaju. Unikalne walory
        smakowe węgorza związane są z wysoką, wahającą się od 20 do 37%,
        zawartością tłuszczu. Oczywiście wiąże się to również z wyższą
        kalorycznością, wynoszącą nieco ponad 300 kcal/100 g ryby. Jednakże
        tłuszcz obecny w węgorzu niesie ze sobą wiele substancji korzystnych dla
        naszego zdrowia. I tak, 100-gramowa porcja tej ryby dostarcza prawie
        2-krotną dawkę kwasów tłuszczowych z rodziny ɷ3, rekomendowaną w
        profilaktyce chorób serca, albo – prawie całą dawkę dzienną dla
        pacjentów z chorobą wieńcową. Węgorz nie zawiera wprawdzie zbyt dużo
        witaminy D3, jest za to zasobny w witaminę E (100 g węgorza dostarcza
        nam ponad połowę rekomendowanej dziennej dawki). Zawiera też potężne
        ilości witaminy A – substancji, która ma istotny wpływ na proces
        widzenia, wzrost, która działa przeciwnowotworowo, wzmacnia układ
        odpornościowy, utrzymuje prawidłowy stan skóry, włosów i paznokci, a
        także wpływa na prawidłowe funkcjonowanie błon komórkowych. Już
        niespełna 60 g węgorza pokrywa dzienne zapotrzebowanie naszego organizmu
        na tę cenną witaminę. Nie należy oczywiście zapominać, że węgorz,
        podobnie jak inne ryby, jest źródłem łatwostrawnego białka, bogatego w
        aminokwasy egzogenne (czyli te, które musimy dostarczyć naszemu
        organizmowi wraz z dietą), w tym cenna lizynę. Mięso węgorza dostarcza
        nam również minerałów, takich jak np. jod, fluor, selen, fosfor czy
        cynk.
      </p>

      <div className="obrazki">
        <ModalImage
          className="ramka"
          small="./galeria/wegorz2_250.jpg"
          large="./galeria/wegorz2_1000.jpg"
          alt="wędzony węgorz"
        />

        <ModalImage
          className="ramka"
          small="./galeria/wegorz3_250.jpg"
          large="./galeria/wegorz3_1000.jpg"
          alt="Smacznego!"
        />

        <ModalImage
          className="ramka"
          small="./galeria/wegorz4_250.jpg"
          large="./galeria/wegorz4_1000.jpg"
          alt="wędzony wegorz"
        />
      </div>
    </div>
  );
}

export default Wegorz;
