import React from 'react';
import ModalImage from 'react-modal-image';
import './Dorsz.css';

function Karp() {
  return (
    <div className="glowna">
      <h4>KARP</h4>

      <ModalImage
        small="./galeria/karp1_250.jpg"
        large="./galeria/karp1_1000.jpg"
        alt="karp"
      />

      <p>
        Karp (Cyprinus carpio) to gatunek słodkowodnej ryby z rodziny
        karpiowatych, hodowany i poławiany na dużą skalę (w Polsce stanowi
        połowę odławianych ryb słodkowodnych). Karpie są hodowane w stawach od
        tysięcy lat, nam kojarzą się jednak wciąż z nie zawsze lubianym daniem
        wigilijnym - uważa się, że jego mięso ma zbyt wiele ości i jest muliste.
        Jednak swego czasu smak karpia zachwycał polskich władców, stąd
        najpopularniejsza odmiana została nazwana karpiem królewskim. Co prawda
        smakosze twierdzą, że najsmaczniejsza jest odmiana zwana karpiem
        pełnołuskim, przyrządzanie jej jest jednak bardziej uciążliwe, ze
        względu na konieczność skrobania ;( Mięso karpia, podobnie jak to jest w
        przypadku innych ryb, jest źródłem łatwo przyswajalnego białka, bogatego
        w cenne aminokwasy egzogenne, takie jak lizyna, izoleucyna, metionina i
        cysteina. Karp zawiera około 5% tłuszczu, jednak jego skład, jeśli
        chodzi o kwasy tłuszczowe, jest nieco mniej korzystny niż w przypadku
        innych ryb – 100-gramowa porcja mięsa karpia dostarcza nam około połowę
        rekomendowanej w profilaktyce chorób serca dawki kwasów EPA i DHA. Nie
        należy jednak zapominać, że 100 g karpia zawiera aż 7,5 µg witaminy D3,
        co przekracza rekomendowaną jeszcze niedawno dawkę dzienną (obecnie, ze
        względu na powszechność niedoborów tej witaminy, zalecane jest wyższe
        spożycie). Karp jest też cennym źródłem fosforu i selenu.
      </p>

      <div className="obrazki">
        <ModalImage
          className="ramka"
          small="./galeria/karp2_250.jpg"
          large="./galeria/karp2_1000.jpg"
          alt="karp"
        />

        <ModalImage
          className="ramka"
          small="./galeria/karp3_250.jpg"
          large="./galeria/karp3_1000.jpg"
          alt="smażony karp"
        />

        <ModalImage
          className="ramka"
          small="./galeria/karp4_250.jpg"
          large="./galeria/karp4_1000.jpg"
          alt="filet z karpia"
        />
      </div>
    </div>
  );
}

export default Karp;
