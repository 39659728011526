import React from 'react';
import ModalImage from 'react-modal-image';
import './Dorsz.css';

function Dorsz() {
  return (
    <div className="glowna">
      <h4>DORSZ</h4>
      <ModalImage
        small="./galeria/dorsz1_250.jpg"
        large="./galeria/dorsz1_1000.jpg"
        alt="dorsz"
      />
      <p>
        Dorsz (po łacinie: Gadus morhua), to drapieżna ryba morska, największa z
        rodziny dorszowatych, występująca w chłodnych akwenach – w północnej
        części Oceanu Atlantyckiego i w morzach północnej Europy, w tym w Morzu
        Bałtyckim.
      </p>

      <p>
        Wiele osób, przez długie lata uważało dorsza za rybę drugiej kategorii,
        do czego przyczyniło się niewątpliwie hasło Wańkowicza, którego –
        pozwólcie – nie będziemy przytaczać. A przecież mięso tej ryby jest nie
        tylko smaczne, ale również zdrowe i lekkostrawne. Charakteryzuje się
        białą barwą, zauważalnym podziałem na oddzielne płatki i delikatnym
        rybnym aromatem. Nie wymaga długotrwałej obróbki termicznej – wręcz
        przeciwnie, należy zdjąć go z patelni gdy tylko zmieni kolor na
        nieprzezroczysty; jeśli tego nie zrobimy, na powierzchni mięsa pojawią
        się bąbelki albuminy oznaczające, że dorsz został przegotowany i mięso
        zaczęło wysychać.{' '}
      </p>

      <p>
        Dorsz to ryba niskokaloryczna (100 gramów to około 80 kcal), która
        jednocześnie dostarcza nam niezwykle wartościowego, łatwo przyswajalnego
        białka o wysokiej zawartości niezbędnych aminokwasów, takich jak lizyna,
        izoleucyna, metionina i cysteina. Dorsz to doskonałe źródło białka dla
        dzieci i młodzieży oraz seniorów również dlatego, że filet z ogona jest
        pozbawiony ości. Kolejną zaletą dorsza jest wysoka zawartość witaminy
        B12. Jego mięso może nam dostarczyć także sporo minerałów, takich jak
        fosfor, selen czy potas. Dorsz jest rybą niezwykle chudą, co ma swoje
        plusy i minusy. Do plusów możemy zaliczyć niską zawartość cholesterolu,
        jak również niewielkie ilości kumulujących się w tłuszczu trwałych
        zanieczyszczeń organicznych. Niestety, niewielka zawartość tłuszczu
        przekłada się na odpowiednio mniejszą ilość kwasów wielonienasyconych w
        porcji zjadanej przez nas ryby, choć proporcja kwasów n-3/n-6 jest
        bardzo korzystna.
      </p>

      <div className="obrazki">
        <ModalImage className="ramka"
          small="./galeria/dorsz2_250.jpg"
          large="./galeria/dorsz2_1000.jpg"
          alt="dorsz"
        />

        <ModalImage className="ramka"
          small="./galeria/dorsz4_250.jpg"
          large="./galeria/dorsz4_1000.jpg"
          alt="smacznego :-)"
        />

        <ModalImage className="ramka"
          small="./galeria/dorsz3_250.jpg"
          large="./galeria/dorsz3_1000.jpg"
          alt="filet z dorsza"
        />
      </div>
    </div>
  );
}

export default Dorsz;
