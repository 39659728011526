import React from 'react';
import ModalImage from 'react-modal-image';
import './Dorsz.css';

function Sledz() {
  return (
    <div className="glowna">
      
      <h4>ŚLEDŹ</h4>
      
      <ModalImage
        small="./galeria/sledz1_250.jpg"
        large="./galeria/sledz1_1000.jpg"
        alt="śledź"
      />

    <p>Śledź (Clupea harengus) to ławicowa ryba morska z rodziny śledziowatych. Zasiedla wody strefy umiarkowanej i chłodnej północnego Atlantyku i Morza Bałtyckiego. Śledzie mają bardzo delikatne i smaczne mięso, które można przyrządzać na wiele sposobów i które gości na naszych stołach od setek lat.  I słusznie, bo zawiera duże ilości (ponad 18%) łatwostrawnego białka, bogatego w niezbędne aminokwasy takie jak metionina, cysteina, lizyna, czy izoleucyna. Chociaż śledzie nie zaliczają się do ryb chudych, należy pamiętać, że tkanka mięśniowa śledzia zawiera,  zależnie od sezonu od 2,5 do 5,5%, podczas gdy „chudy” kurczak zawiera go 8,6 - 10%. Jednak mimo nieco wyższej zawartości tłuszczu, śledzie wcale nie mają dużo kalorii (porcja 100 g świeżego śledzia to około 160 kcal). Są za to jest zasobne w tak potrzebna nam witaminę D3 i w wielonienasycone kwasy tłuszczowe ɷ3 – substancje o wielokierunkowym działaniu prozdrowotnym. Śledzie są też świetnym źródłem minerałów takich jak selen, fosfor, czy jod. </p>

    <div className="obrazki">
        <ModalImage className="ramka"
          small="./galeria/sledz2_250.jpg"
          large="./galeria/sledz2_1000.jpg"
          alt="koreczki śledziowe"
        />

        <ModalImage className="ramka"
          small="./galeria/sledz4_250.jpg"
          large="./galeria/sledz4_1000.jpg"
          alt="Smacznego!"
        />

        <ModalImage className="ramka"
          small="./galeria/sledz3_250.jpg"
          large="./galeria/sledz3_1000.jpg"
          alt="potrawy ze śledzia"
        />
      </div>


    </div>
  );
}

export default Sledz;
