import React from 'react';
import ModalImage from 'react-modal-image';
import './Dorsz.css';

function Okon() {
  return (
    <div className="glowna">
      <h4>OKOŃ</h4>

      <ModalImage
        small="./galeria/okon1_250.jpg"
        large="./galeria/okon1_1000.jpg"
        alt="okoń"
      />

      <p>
      Okoń (Perca fluviatilis) to gatunek drapieżnej ryby z rodziny okoniowatych, występującej w stawach, jeziorach, rzekach i płytkich wodach Bałtyku. Mięso okonia jest białe i delikatne, choć niestety dość ościste, ale podczas smażenia i pieczenia ości kruszeją. Okoń należy do chudych ryb – zawiera średnio 0,12% tłuszczu, a 100 g tej ryby dostarcza nam około 73 kcal. Niestety, niewielka zawartość tłuszczu przekłada się na odpowiednio mniejszą ilość kwasów wielonienasyconych w porcji zjadanej przez nas ryby, choć proporcja kwasów n-3/n-6 jest bardzo korzystna. Okoń jest  za to doskonałym źródłem pełnowartościowego białka, oraz minerałów takich jak jod, fosfor, selen, fluor i potas. Porcja okonia o wielkości 100 g dostarcza nam też około 5 µg witaminy D3, co jeszcze niedawno było rekomendowaną dawką dzienną (obecnie została ona podniesiona ze względu na powszechność niedoborów tej witaminy).  
      </p>

      <div className="obrazki">
        <ModalImage
          className="ramka"
          small="./galeria/okon2_250.jpg"
          large="./galeria/okon2_1000.jpg"
          alt="okon - filet"
        />

        <ModalImage
          className="ramka"
          small="./galeria/okon3_250.jpg"
          large="./galeria/okon3_1000.jpg"
          alt="Smacznego!"
        />

        <ModalImage
          className="ramka"
          small="./galeria/okon4_250.jpg"
          large="./galeria/okon4_1000.jpg"
          alt="smażony okoń"
        />
      </div>
    </div>
  );
}

export default Okon;
