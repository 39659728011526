import React from 'react';
import ModalImage from 'react-modal-image';
import './Dorsz.css';

function Losos() {
  return (
    <div className="glowna">
      <h4>ŁOSOŚ</h4>

      <ModalImage
        small="./galeria/losos1_250.jpg"
        large="./galeria/losos1_1000.jpg"
        alt="łosoś"
      />

      <p>
        Łosoś (Salmo salar) to ryba dwuśrodowiskowa, anadromiczna, tj. wędrująca
        na czas rozrodu z mórz do rzek. Występuje w północnej części Atlantyku,
        w rzekach Ameryki Północnej, w Europie od Portugalii po Morze Białe,
        Północne i Bałtyk. Łosoś bałtycki, w odróżnieniu od norweskiego, nie
        pochodzi z hodowli, a jego mięso ma żółtawy kolor. Jest to mięso o
        wysokiej wartości odżywczej – jego 100 g zawiera 18,4 g łatwostrawnego
        białka, bogatego w aminokwasy niezbędne, m. in. w metioninę, cysteinę,
        izoleucynę i lizynę. Zawiera też nieco ponad 13% tłuszczu, co trochę,
        choć nie przesadnie, podwyższa wartość kaloryczną łososia bałtyckiego –
        porcja 100 g dostarcza nam około 200 kcal. Łosoś bałtycki, tu
        chcielibyśmy mocno podkreślić jego przewagę nad hodowlanym - jest
        doskonałym źródłem niezbędnych, nienasyconych kwasów tłuszczowych z
        rodziny ɷ3 – 100 g tej ryby dostarcza nam prawie 8-krotność
        rekomendowanej w profilaktyce chorób serca dawki dziennej kwasów EPA i
        DHA. Jest też zasobny w witaminę D3 oraz minerały takie jak selen i
        fosfor.
      </p>

      <div className="obrazki">
        <ModalImage
          className="ramka"
          small="./galeria/losos2_250.jpg"
          large="./galeria/losos2_1000.jpg"
          alt="zupa"
        />

        <ModalImage
          className="ramka"
          small="./galeria/losos4_250.jpg"
          large="./galeria/losos4_1000.jpg"
          alt="smacznego..."
        />

        <ModalImage
          className="ramka"
          small="./galeria/losos3_250.jpg"
          large="./galeria/losos3_1000.jpg"
          alt="łosoś"
        />
      </div>
    </div>
  );
}

export default Losos;
