import React from 'react';
import ModalImage from 'react-modal-image';
import './Dorsz.css';

function Szprot() {
  return (
    <div className="glowna">
      <h4>SZPROT</h4>

      <ModalImage
        small="./galeria/szprot1_250.jpg"
        large="./galeria/szprot1_1000.jpg"
        alt="szprot"
      />

      <p>
        Szprot (Sprattus sprattus) to gatunek małej ryby morskiej z rodziny
        śledziowatych, występujący we wschodniej części Oceanu Atlantyckiego i
        morzach Europy: Morzu Północnym, Bałtyckim i w północnych rejonach Morza
        Śródziemnego i Czarnego. Mięso szprota jest ścisłe, soczyste, o
        charakterystycznym, wyrazistym smaku. W 100 g porcji znajdziemy prawie
        17 g łatwostrawnego białka i od 4 do 17 g tłuszczu (w zależności od
        sezonu w jakim szprot był złowiony), średnio około 12 g. Kaloryczność
        świeżego szprota to średnio 140 kcal/100 g. Będzie ona oczywiście
        zależała nieco od zawartości tłuszczu w konkretnej porcji ryb, ale
        jeszcze bardziej – od sposobu jej przyrządzenia. Usmażona w głębokim
        oleju szprotka może być smaczna, ale jej kaloryczność poszybuje w górę,
        a proporcja kwasów ɷ3 spadnie. Dobrym źródłem substancji prozdrowotnych,
        w tym kwasów tłuszczowych z rodziny ɷ3, witaminy D3 i minerałów, takich
        jak np. selen, fluor, fosfor czy jod są też rozmaite konserwy ze
        szprota.
      </p>

      <div className="obrazki">
        <ModalImage
          className="ramka"
          small="./galeria/szprot2_250.jpg"
          large="./galeria/szprot2_1000.jpg"
          alt="szprot w puszce"
        />

        <ModalImage
          className="ramka"
          small="./galeria/szprot4_250.jpg"
          large="./galeria/szprot4_1000.jpg"
          alt="smacznego..."
        />

        <ModalImage
          className="ramka"
          small="./galeria/szprot3_250.jpg"
          large="./galeria/szprot3_1000.jpg"
          alt="szprot wędzony"
        />
      </div>
    </div>
  );
}

export default Szprot;
