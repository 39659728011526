import React from 'react';
import ModalImage from 'react-modal-image';
import './Dorsz.css';

function Ploc() {
  return (
    <div className="glowna">
      <h4>PŁOĆ</h4>

      <ModalImage
        small="./galeria/ploc1_250.jpg"
        large="./galeria/ploc1_1000.jpg"
        alt="płoć"
      />

      <p>
        Płoć (Rutilus rutilus) to gatunek ryby z rodziny karpiowatych,
        występujący w Polsce we wszystkich wodach słodkich (w rzekach, jeziorach
        i stawach , z wyjątkiem gór), a także w wodach przybrzeżnych Bałtyku.
        Mięso płoci jest białe, chude, delikatne, bardzo smaczne, jeśli usuniemy
        lekki posmak mułu. Jest wprawdzie ościste, lecz ości bardzo łatwo daje
        się usunąć. Płoć jest rybą chudą (0,1-1,1% tłuszczu), a co za tym idzie
        niskokaloryczną (78 kcal w 100 g). Stanowi za to świetne źródło łatwo
        przyswajalnego białka, bogatego m. in. w lizynę, a także minerałów
        takich jak jod, fosfor, selen czy fluor.
      </p>

      <div className="obrazki">
        <ModalImage
          className="ramka"
          small="./galeria/ploc2_250.jpg"
          large="./galeria/ploc2_1000.jpg"
          alt="smażona płoć"
        />

        <ModalImage
          className="ramka"
          small="./galeria/ploc3_250.jpg"
          large="./galeria/ploc3_1000.jpg"
          alt="Smacznego!"
        />

        <ModalImage
          className="ramka"
          small="./galeria/ploc4_250.jpg"
          large="./galeria/ploc4_1000.jpg"
          alt="smażona płoć"
        />
      </div>
    </div>
  );
}

export default Ploc;
