import React from 'react';
import ModalImage from 'react-modal-image';
import './Dorsz.css';

function Stornia() {
  return (
    <div className="glowna">
      <h4>STORNIA</h4>

      <ModalImage
        small="./galeria/stornia1_250.jpg"
        large="./galeria/stornia1_1000.jpg"
        alt="stornia"
      />

      <p>
        Stornia, zwana też flądrą (Platichthys flesus) to gatunek ryby
        flądrokształtnej, czyli charakteryzującej się silnie spłaszczonym
        bocznie, owalnym ciałem. Stornia przez większość czasu spoczywa na dnie
        morskim leżąc na boku ciała; tak się również porusza. W związku z tym
        jedno jej oko przemieszczone jest na przeciwną stronę głowy. Stornia
        zamieszkuje Europejskie wybrzeża Oceanu Atlantyckiego, Morze Śródziemne,
        Bałtyckie, Czarne i Białe. Jej mięso jest białe, delikatne i soczyste, o
        charakterystycznym maślanym smaku. Stornia należy do ryb chudych, a jej
        100-gramowa porcja dostarcza nam około 80 kcal. Podobnie jak inne ryby
        stanowi doskonałe źródło łatwostrawnego białka, dostarcza nam również
        kwasów tłuszczowych z rodziny ɷ3 i minerałów, takich jak selen czy
        fosfor.
      </p>

      <div className="obrazki">
        <ModalImage
          className="ramka"
          small="./galeria/stornia2_250.jpg"
          large="./galeria/stornia2_1000.jpg"
          alt="stornia - filet"
        />

        <ModalImage
          className="ramka"
          small="./galeria/stornia3_250.jpg"
          large="./galeria/stornia3_1000.jpg"
          alt="Smacznego!"
        />

        <ModalImage
          className="ramka"
          small="./galeria/stornia4_250.jpg"
          large="./galeria/stornia4_1000.jpg"
          alt="smażona stornia"
        />
      </div>
    </div>
  );
}

export default Stornia;
