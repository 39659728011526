import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';

export class MeHgData extends Component {
  constructor() {
    super();
    this.state = {
      chartData: {},
      waga: 70,
      zapotrzebowanie: 1.6 * 70,
      porcjaGram: 100,
      displayTitle: true,
      displayLegend: true,
      legendPosition: 'bottom',
      tytul: 'Zawartość metylortęci (Me-Hg) w poszczególnych gatunkach ryb [mikrogramy]'
    };
  }

  componentDidMount() {
    this.getChartData();
  }

  getChartData() {
    // Ajax calls here
    this.setState({
      chartData: {
        labels: [
          'dorsz',
          'śledź',
          'łosoś bałtycki',
          'szprot',
          'stornia',
          'okoń',
          'płoć',
          'sandacz',
          'leszcz',
          'węgorz',
          'karp',
          'pstrąg'
        ],
        datasets: [
          {
            label: 'metylortęć [mikrogram]',
            data: [
              Math.round(0.0425 * this.state.porcjaGram * 100) / 100,
              Math.round(0.0592 * this.state.porcjaGram * 100) / 100,
              Math.round(0.0476 * this.state.porcjaGram * 100) / 100,
              Math.round(0.0297 * this.state.porcjaGram * 100) / 100,
              Math.round(0.0558 * this.state.porcjaGram * 100) / 100,
              Math.round(0.0801 * this.state.porcjaGram * 100) / 100,
              Math.round(0.0495 * this.state.porcjaGram * 100) / 100,
              Math.round(0.0459 * this.state.porcjaGram * 100) / 100,
              Math.round(0.0216 * this.state.porcjaGram * 100) / 100,
              Math.round(0.0711 * this.state.porcjaGram * 100) / 100,
              Math.round(0.0303 * this.state.porcjaGram * 100) / 100,
              Math.round(0.0493 * this.state.porcjaGram * 100) / 100
            ],
            backgroundColor: [
              'rgba(255, 242, 0, 0.6)',
              'rgba(253, 116, 3, 0.6)',
              'rgba(252, 163, 4, 0.6)',
              'rgba(0, 0, 255, 0.6)',
              'rgba(0, 100, 0, 0.6)',
              'rgba(146, 208, 80, 0.6)',
              'rgba(0, 176, 80, 0.6)',
              'rgba(0, 176, 240, 0.6)',
              'rgba(0, 112, 192, 0.6)',
              'rgba(0, 32, 96, 0.6)',
              'rgba(112, 48, 160, 0.6)',
              'rgba(233, 9, 238, 0.6)'
            ]
          },
          {
            label: 'dopuszczalne tygodniowe pobranie metylortęci (Me-Hg)',
            data: [
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie
            ],
            type: 'line'
          }
        ]
      }
    });
  }

  handleChangeWaga = event => {
    this.setState({
      waga: event.target.value,
      // zapotrzebowanie: event.target.value * 0.0094
      zapotrzebowanie: Math.round(event.target.value * 1.6 * 100) / 100
    });
  };

  handlePorcjaGram = event => {
    this.setState({
      porcjaGram: event.target.value
    });
  };

  handleSubmit = event => {
    // alert(`${this.state.zapotrzebowanie}`);
    this.setState({
      chartData: {
        labels: [
          'dorsz',
          'śledź',
          'łosoś bałtycki',
          'szprot',
          'stornia',
          'okoń',
          'płoć',
          'sandacz',
          'leszcz',
          'węgorz',
          'karp',
          'pstrąg'
        ],
        datasets: [
          {
            label: 'metylortęć [mikrogramy]',
            data: [
                Math.round(0.0425 * this.state.porcjaGram * 100) / 100,
                Math.round(0.0592 * this.state.porcjaGram * 100) / 100,
                Math.round(0.0476 * this.state.porcjaGram * 100) / 100,
                Math.round(0.0297 * this.state.porcjaGram * 100) / 100,
                Math.round(0.0558 * this.state.porcjaGram * 100) / 100,
                Math.round(0.0801 * this.state.porcjaGram * 100) / 100,
                Math.round(0.0495 * this.state.porcjaGram * 100) / 100,
                Math.round(0.0459 * this.state.porcjaGram * 100) / 100,
                Math.round(0.0216 * this.state.porcjaGram * 100) / 100,
                Math.round(0.0711 * this.state.porcjaGram * 100) / 100,
                Math.round(0.0303 * this.state.porcjaGram * 100) / 100,
                Math.round(0.0493 * this.state.porcjaGram * 100) / 100
            ],
            backgroundColor: [
              'rgba(255, 242, 0, 0.6)',
              'rgba(253, 116, 3, 0.6)',
              'rgba(252, 163, 4, 0.6)',
              'rgba(0, 0, 255, 0.6)',
              'rgba(0, 100, 0, 0.6)',
              'rgba(146, 208, 80, 0.6)',
              'rgba(0, 176, 80, 0.6)',
              'rgba(0, 176, 240, 0.6)',
              'rgba(0, 112, 192, 0.6)',
              'rgba(0, 32, 96, 0.6)',
              'rgba(112, 48, 160, 0.6)',
              'rgba(233, 9, 238, 0.6)'
            ]
          },
          {
            label: 'dopuszczalne tygodniowe pobranie metylortęci (Me-Hg)',
            data: [
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie
            ],
            type: 'line'
          }
        ]
      }
    });
    event.preventDefault();
  };

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              marginTop: '20px'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <label>Twoja waga (kg)</label>
              <input
                type="text"
                value={this.state.waga}
                onChange={this.handleChangeWaga}
                style={{ width: '200px', textAlign: 'center' }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <label>Porcja ryby (gramy)</label>
              <input
                type="text"
                value={this.state.porcjaGram}
                onChange={this.handlePorcjaGram}
                style={{ width: '200px', textAlign: 'center' }}
              />
            </div>
          </div>
          <br />
          <button type="submit" className="btn btn-success">
            Przelicz
          </button>
        </form>

        <div className="chart">
          <Bar
            data={this.state.chartData}
            options={{
              title: {
                display: this.state.displayTitle,
                text: this.state.tytul,
                fontSize: 25
              },
              legend: {
                display: this.state.displayLegend,
                position: this.state.legendPosition
              },
              maintainAspectRatio: true,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      min: 0
                    }
                  }
                ]
              }
            }}
          />
          <div style={{fontSize: "12px", color: "rgb(240, 108, 0)"}}>UWAGA: Kliknij na powyższy szary prostokąt aby włączyć/wyłączyć wyświetlanie dopuszczalnej dawki</div>
        </div>
      </div>
    );
  }
}

export default MeHgData;
