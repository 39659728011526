import React from 'react';
import PoczatekButton from './PoczatekButton';
import './ZielonyPas.css';

function ZielonyPas(props) {
  return (
    <footer id="footer">
      <div className="inner">
        <h4 className="pierwszeh4">
          Projekt sfinansowany z Funduszu Promocji Ryb
        </h4>
        <h4 className="drugie4">
          © Morski Instytut Rybacki - Państwowy Instytut Badawczy
          <br />
          tel.: +48 58 73-56-232
          <br />
          e-mail: sekretariat@mir.gdynia.pl
        </h4>
      </div>
      <div className="poczBut">
        <PoczatekButton />
      </div>
    </footer>
  );
}

export default ZielonyPas;
