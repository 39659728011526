import React from 'react';
import ModalImage from 'react-modal-image';
import './Dorsz.css';

function Leszcz() {
    return (
        <div className="glowna">
        <h4>LESZCZ</h4>
  
        <ModalImage
          small="./galeria/leszcz1_250.jpg"
          large="./galeria/leszcz1_1000.jpg"
          alt="leszcz"
        />
  
        <p>
        Leszcz (Abramis brama) to gatunek słodkowodnej, drapieżnej ryby z rodziny karpiowatych, występujący w niemal całej Europie. W Polsce jest pospolity w dużych jeziorach, rzekach nizinnych i w przybrzeżnej strefie Bałtyku. Mięso leszcza jest delikatne w smaku, ma jednak skłonność do rozpadania się i jest ościste. Warto jednak by gościło na naszych stołach, gdyż w 100-gramowej porcji zawiera 18 g łatwostrawnego białka, bogatego w cenny aminokwas egzogenny – lizynę. Leszcz ma zawartość tłuszczu porównywalną ze śledziem (ok. 2,5%), a co za tym idzie porównywalną kaloryczność (około 96 kcal/100 g). Spożycie 100 g tej ryby dostarcza naszemu organizmowi około 7,5 µg witaminy D3, co przekracza rekomendowaną jeszcze niedawno dawkę dzienną (obecnie została ona podniesiona ze względu na powszechność niedoborów tej witaminy).  Leszcz jest też cennym źródłem jodu, fosforu, selenu i fluoru.
        </p>
  
        <div className="obrazki">
          <ModalImage
            className="ramka"
            small="./galeria/leszcz2_250.jpg"
            large="./galeria/leszcz2_1000.jpg"
            alt="zupa"
          />
  
          <ModalImage
            className="ramka"
            small="./galeria/leszcz3_250.jpg"
            large="./galeria/leszcz3_1000.jpg"
            alt="Smacznego!"
          />
  
          <ModalImage
            className="ramka"
            small="./galeria/leszcz4_250.jpg"
            large="./galeria/leszcz4_1000.jpg"
            alt="smażony leszcz"
          />
        </div>
      </div>
    )
}

export default Leszcz
