import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';

export class KwTluszczoweData extends Component {
  constructor() {
    super();
    this.state = {
      chartData: {},
      waga: 70,
      chorobySerca: true,
      chorobaWiencowa: false,
      zapotrzebowanie: 500,
      porcjaGram: 100,
      displayTitle: true,
      displayLegend: true,
      kobMen: 'w profilaktyce chorób serca',
      legendPosition: 'bottom',
      tytul: 'Zawartość EPA + DHA w poszczególnych gatunkach ryb'
    };
  }

  componentDidMount() {
    this.getChartData();
  }

  getChartData() {
    // Ajax calls here
    if (this.state.chorobySerca) {
      this.setState({
        zapotrzebowanie: 500,
        kobMen: 'mężczyźni'
      });
    } else {
      this.setState({
        zapotrzebowanie: 70,
        kobMen: 'kobiety'
      });
    }
    this.setState({
      chartData: {
        labels: [
          'dorsz',
          'śledź',
          'łosoś bałtycki',
          'szprot',
          'stornia',
          'okoń',
          'płoć',
          'sandacz',
          'leszcz',
          'węgorz',
          'karp',
          'pstrąg'
        ],
        datasets: [
          {
            label: 'mg EPA + DHA',
            data: [
              Math.round(0.45 * this.state.porcjaGram * 100) / 100,
              Math.round(9.41 * this.state.porcjaGram * 100) / 100,
              Math.round(38.07 * this.state.porcjaGram * 100) / 100,
              Math.round(25.88 * this.state.porcjaGram * 100) / 100,
              Math.round(2.15 * this.state.porcjaGram * 100) / 100,
              Math.round(1.71 * this.state.porcjaGram * 100) / 100,
              Math.round(1.39 * this.state.porcjaGram * 100) / 100,
              Math.round(1.02 * this.state.porcjaGram * 100) / 100,
              Math.round(1.38 * this.state.porcjaGram * 100) / 100,
              Math.round(9.22 * this.state.porcjaGram * 100) / 100,
              Math.round(2.15 * this.state.porcjaGram * 100) / 100,
              Math.round(18.04 * this.state.porcjaGram * 100) / 100
            ],
            backgroundColor: [
              'rgba(255, 242, 0, 0.6)',
              'rgba(253, 116, 3, 0.6)',
              'rgba(252, 163, 4, 0.6)',
              'rgba(0, 0, 255, 0.6)',
              'rgba(0, 100, 0, 0.6)',
              'rgba(146, 208, 80, 0.6)',
              'rgba(0, 176, 80, 0.6)',
              'rgba(0, 176, 240, 0.6)',
              'rgba(0, 112, 192, 0.6)',
              'rgba(0, 32, 96, 0.6)',
              'rgba(112, 48, 160, 0.6)',
              'rgba(233, 9, 238, 0.6)'
            ]
          },
          {
            label: 'rekomendowana dawka dzienna ' + this.state.kobMen,
            data: [
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie
            ],
            type: 'line'
          }
        ]
      }
    });
  }

  handleChangeWaga = event => {
    this.setState({
      waga: event.target.value,
      // zapotrzebowanie: event.target.value * 0.0094
      zapotrzebowanie: Math.round(event.target.value * 0.9 * 100) / 100
    });
  };

  handlePorcjaGram = event => {
    this.setState({
      porcjaGram: event.target.value
    });
  };

  handleSubmit = event => {
    // alert(`${this.state.zapotrzebowanie}`);
    this.setState({
      chartData: {
        labels: [
          'dorsz',
          'śledź',
          'łosoś bałtycki',
          'szprot',
          'stornia',
          'okoń',
          'płoć',
          'sandacz',
          'leszcz',
          'węgorz',
          'karp',
          'pstrąg'
        ],
        datasets: [
          {
            label: 'mg EPA + DHA',
            data: [
                Math.round(0.45 * this.state.porcjaGram * 100) / 100,
                Math.round(9.41 * this.state.porcjaGram * 100) / 100,
                Math.round(38.07 * this.state.porcjaGram * 100) / 100,
                Math.round(25.88 * this.state.porcjaGram * 100) / 100,
                Math.round(2.15 * this.state.porcjaGram * 100) / 100,
                Math.round(1.71 * this.state.porcjaGram * 100) / 100,
                Math.round(1.39 * this.state.porcjaGram * 100) / 100,
                Math.round(1.02 * this.state.porcjaGram * 100) / 100,
                Math.round(1.38 * this.state.porcjaGram * 100) / 100,
                Math.round(9.22 * this.state.porcjaGram * 100) / 100,
                Math.round(2.15 * this.state.porcjaGram * 100) / 100,
                Math.round(18.04 * this.state.porcjaGram * 100) / 100
            ],
            backgroundColor: [
                'rgba(255, 242, 0, 0.6)',
                'rgba(253, 116, 3, 0.6)',
                'rgba(252, 163, 4, 0.6)',
                'rgba(0, 0, 255, 0.6)',
                'rgba(0, 100, 0, 0.6)',
                'rgba(146, 208, 80, 0.6)',
                'rgba(0, 176, 80, 0.6)',
                'rgba(0, 176, 240, 0.6)',
                'rgba(0, 112, 192, 0.6)',
                'rgba(0, 32, 96, 0.6)',
                'rgba(112, 48, 160, 0.6)',
                'rgba(233, 9, 238, 0.6)'
            ]
          },
          {
            label: 'rekomendowana dawka dzienna ' + this.state.kobMen,
            data: [
                this.state.zapotrzebowanie,
                this.state.zapotrzebowanie,
                this.state.zapotrzebowanie,
                this.state.zapotrzebowanie,
                this.state.zapotrzebowanie,
                this.state.zapotrzebowanie,
                this.state.zapotrzebowanie,
                this.state.zapotrzebowanie,
                this.state.zapotrzebowanie,
                this.state.zapotrzebowanie,
                this.state.zapotrzebowanie,
                this.state.zapotrzebowanie
            ],
            type: 'line'
          }
        ]
      }
    });
    event.preventDefault();
  };

  handleOptionChange = changeEvent => {
    // console.log(changeEvent.target.value);
    this.setState({
      chorobySerca: !this.state.chorobySerca,
      chorobaWiencowa: !this.state.chorobaWiencowa
    });
    if (changeEvent.target.value === 'chorobySerca') {
      this.setState({
        zapotrzebowanie: 500,
        kobMen: 'w profilaktyce chorób serca'
      });
    }
    if (changeEvent.target.value === 'chorobaWiencowa') {
      this.setState({
        zapotrzebowanie: 1000,
        kobMen: 'dla pacjentów z chorobą wieńcową'
      });
    }
  };

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              marginTop: '20px'
            }}
          >
              <label>Rekomendowana dawka dzienna</label>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: "20px" }}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  value="chorobySerca"
                  checked={this.state.chorobySerca}
                  onChange={this.handleOptionChange}
                />
                <label className="form-check-label" htmlFor="exampleRadios1">
                  w profilaktyce chorób serca
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  value="chorobaWiencowa"
                  onChange={this.handleOptionChange}
                />
                <label className="form-check-label" htmlFor="exampleRadios2">
                  dla pacjentów z chorobą wieńcową
                </label>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <label>Porcja ryby (gramy)</label>
              <input
                type="text"
                value={this.state.porcjaGram}
                onChange={this.handlePorcjaGram}
                style={{ width: '200px', textAlign: 'center' }}
              />
            </div>
          </div>
          <br />
          <button type="submit" className="btn btn-success">
            Przelicz
          </button>
        </form>
        {/* <Chart chartData={this.state.chartData} tytul="Zawartość lizyny w poszczególnych gatunkach ryb" legendPosition="bottom"/>   */}
        <div className="chart">
          <Bar
            data={this.state.chartData}
            options={{
              title: {
                display: this.state.displayTitle,
                text: this.state.tytul,
                fontSize: 25
              },
              legend: {
                display: this.state.displayLegend,
                position: this.state.legendPosition
              },
              maintainAspectRatio: true,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      min: 0
                    }
                  }
                ]
              }
            }}
          />
        </div>
      </div>
    );
  }
}

export default KwTluszczoweData;
