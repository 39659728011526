import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import PoczatekButton from './PoczatekButton';
import ZielonyPas from './ZielonyPas';
import Kalkulator from './Kalkulator';
import Dorsz from './gatunki/Dorsz';

import './StronaGlowna.css';
import Sledz from './gatunki/Sledz';
import Losos from './gatunki/Losos';
import Szprot from './gatunki/Szprot';
import Stornia from './gatunki/Stornia';
import Okon from './gatunki/Okon';
import Ploc from './gatunki/Ploc';
import Sandacz from './gatunki/Sandacz';
import Leszcz from './gatunki/Leszcz';
import Wegorz from './gatunki/Wegorz';
import Karp from './gatunki/Karp';
import Pstrag from './gatunki/Pstrag';

class StronaGlowna extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dorsz: true,
      sledz: false,
      losos: false,
      szprot: false,
      stornia: false,
      okon: false,
      ploc: false,
      sandacz: false,
      leszcz: false,
      wegorz: false,
      karp: false,
      pstrag: false
    };
  }

  handleSpecimenType = event => {
    const gatunek = event.target.value;
    switch (gatunek) {
      case 'dorsz':
        this.setState({
          dorsz: true,
          sledz: false,
          losos: false,
          szprot: false,
          stornia: false,
          okon: false,
          ploc: false,
          sandacz: false,
          leszcz: false,
          wegorz: false,
          karp: false,
          pstrag: false
        });
        break;
      case 'sledz':
        this.setState({
          dorsz: false,
          sledz: true,
          losos: false,
          szprot: false,
          stornia: false,
          okon: false,
          ploc: false,
          sandacz: false,
          leszcz: false,
          wegorz: false,
          karp: false,
          pstrag: false
        });
        break;
      case 'losos':
        this.setState({
          dorsz: false,
          sledz: false,
          losos: true,
          szprot: false,
          stornia: false,
          okon: false,
          ploc: false,
          sandacz: false,
          leszcz: false,
          wegorz: false,
          karp: false,
          pstrag: false
        });
        break;
      case 'szprot':
        this.setState({
          dorsz: false,
          sledz: false,
          losos: false,
          szprot: true,
          stornia: false,
          okon: false,
          ploc: false,
          sandacz: false,
          leszcz: false,
          wegorz: false,
          karp: false,
          pstrag: false
        });
        break;
      case 'stornia':
        this.setState({
          dorsz: false,
          sledz: false,
          losos: false,
          szprot: false,
          stornia: true,
          okon: false,
          ploc: false,
          sandacz: false,
          leszcz: false,
          wegorz: false,
          karp: false,
          pstrag: false
        });
        break;
      case 'okon':
        this.setState({
          dorsz: false,
          sledz: false,
          losos: false,
          szprot: false,
          stornia: false,
          okon: true,
          ploc: false,
          sandacz: false,
          leszcz: false,
          wegorz: false,
          karp: false,
          pstrag: false
        });
        break;
      case 'ploc':
        this.setState({
          dorsz: false,
          sledz: false,
          losos: false,
          szprot: false,
          stornia: false,
          okon: false,
          ploc: true,
          sandacz: false,
          leszcz: false,
          wegorz: false,
          karp: false,
          pstrag: false
        });
        break;
      case 'sandacz':
        this.setState({
          dorsz: false,
          sledz: false,
          losos: false,
          szprot: false,
          stornia: false,
          okon: false,
          ploc: false,
          sandacz: true,
          leszcz: false,
          wegorz: false,
          karp: false,
          pstrag: false
        });
        break;
      case 'leszcz':
        this.setState({
          dorsz: false,
          sledz: false,
          losos: false,
          szprot: false,
          stornia: false,
          okon: false,
          ploc: false,
          sandacz: false,
          leszcz: true,
          wegorz: false,
          karp: false,
          pstrag: false
        });
        break;
      case 'wegorz':
        this.setState({
          dorsz: false,
          sledz: false,
          losos: false,
          szprot: false,
          stornia: false,
          okon: false,
          ploc: false,
          sandacz: false,
          leszcz: false,
          wegorz: true,
          karp: false,
          pstrag: false
        });
        break;
      case 'karp':
        this.setState({
          dorsz: false,
          sledz: false,
          losos: false,
          szprot: false,
          stornia: false,
          okon: false,
          ploc: false,
          sandacz: false,
          leszcz: false,
          wegorz: false,
          karp: true,
          pstrag: false
        });
        break;
      case 'pstrag':
        this.setState({
          dorsz: false,
          sledz: false,
          losos: false,
          szprot: false,
          stornia: false,
          okon: false,
          ploc: false,
          sandacz: false,
          leszcz: false,
          wegorz: false,
          karp: false,
          pstrag: true
        });
        break;
      default:
        this.setState({
          dorsz: true,
          sledz: false,
          losos: false,
          szprot: false,
          stornia: false,
          okon: false,
          ploc: false,
          sandacz: false,
          leszcz: false,
          wegorz: false,
          karp: false,
          pstrag: false
        });
    }
  };

  render() {
    return (
      <div>
        <section id="banner">
          <div className="inner">
            <header>
              {/* <h1>Jedzmy Nasze Ryby</h1> */}
            </header>

            <br />

            <div className="flex">

              <div>
                <a href="#gatunki">
                  <div className="card1" id="gatunkiIcon">
                    <div className="card1-image"></div>
                    <div className="card1-text">

                    </div>
                    <div className="card1-stats"></div>
                  </div>
                </a>
              </div>


              <div>
                <a href="superfood.html">
                  <div className="card2" id="superfoodIcon">
                    <div className="card2-image"></div>
                    <div className="card2-text">

                    </div>
                    <div className="card2-stats"></div>
                  </div>
                </a>
              </div>

              <div>
                <a href="#kalkulator">
                  <div className="card3" id="kalkulatorIcon">
                    <div className="card3-image"></div>
                    <div className="card3-text">

                    </div>
                    <div className="card3-stats"></div>
                  </div>
                </a>
              </div>
            </div>

            <br />
            <br />
            <br />

            <div className="flex ">

              <div>
                <a href="toksyczne.html">
                  <div className="card4" id="toksIcon">
                    <div className="card4-image"></div>
                    <div className="card4-text">

                    </div>
                    <div className="card4-stats"></div>
                  </div>
                </a>
              </div>

              <div>
                <a href="prasa.html">
                  <div className="card5" id="prasaIcon">
                    <div className="card5-image"></div>
                    <div className="card5-text">

                    </div>
                    <div className="card5-stats"></div>
                  </div>
                </a>
              </div>

              {/* <div id="faqIcon">
                <a href="faq.html">
                  <span className="icon fa-question"></span>
                  <h3>FAQ</h3>
                  <p>
                    Czyli pytania, które <br />
                    niekoniecznie wpadły
                    <br />
                    nam do głowy
                  </p>
                </a>
              </div> */}
              <div>
                <a href="faq.html">
                  <div className="card6" id="faqIcon">
                    <div className="card6-image"></div>
                    <div className="card6-text">

                    </div>
                    <div className="card6-stats"></div>
                  </div>
                </a>
              </div>



            </div>

            <PoczatekButton />
          </div>
        </section>

        <div className="odstep" id="kalkulator">
          <h2>KALKULATOR</h2>
        </div>

        <div>
          <Kalkulator />
        </div>

        <div className="odstep" id="gatunki">
          <h2>GATUNKI RYB</h2>
        </div>

        <div className="container" style={{ marginTop: '20px' }}>
          <ul
            className="nav"
            style={{
              marginBottom: '10px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <li className="nav-item">
              <button
                className="btn btn btn-primary"
                onClick={this.handleSpecimenType}
                value="dorsz"
              >
                Dorsz
              </button>
            </li>

            <li className="nav-item">
              <button
                className="btn btn btn-primary"
                onClick={this.handleSpecimenType}
                value="sledz"
              >
                Śledź
              </button>
            </li>

            <li className="nav-item">
              <button
                className="btn btn btn-primary"
                onClick={this.handleSpecimenType}
                value="losos"
              >
                Łosoś
              </button>
            </li>

            <li className="nav-item">
              <button
                className="btn btn btn-primary"
                onClick={this.handleSpecimenType}
                value="szprot"
              >
                Szprot
              </button>
            </li>

            <li className="nav-item">
              <button
                className="btn btn btn-primary"
                onClick={this.handleSpecimenType}
                value="stornia"
              >
                Stornia
              </button>
            </li>

            <li className="nav-item">
              <button
                className="btn btn btn-primary"
                onClick={this.handleSpecimenType}
                value="okon"
              >
                Okoń
              </button>
            </li>
          </ul>

          <ul
            className="nav"
            style={{
              marginBottom: '40px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <li className="nav-item">
              <button
                className="btn btn btn-primary"
                onClick={this.handleSpecimenType}
                value="ploc"
              >
                Płoć
              </button>
            </li>

            <li className="nav-item">
              <button
                className="btn btn btn-primary"
                onClick={this.handleSpecimenType}
                value="sandacz"
              >
                Sandacz
              </button>
            </li>

            <li className="nav-item">
              <button
                className="btn btn btn-primary"
                onClick={this.handleSpecimenType}
                value="leszcz"
              >
                Leszcz
              </button>
            </li>

            <li className="nav-item">
              <button
                className="btn btn btn-primary"
                onClick={this.handleSpecimenType}
                value="wegorz"
              >
                Węgorz
              </button>
            </li>

            <li className="nav-item">
              <button
                className="btn btn btn-primary"
                onClick={this.handleSpecimenType}
                value="karp"
              >
                Karp
              </button>
            </li>

            <li className="nav-item">
              <button
                className="btn btn btn-primary"
                onClick={this.handleSpecimenType}
                value="pstrag"
              >
                Pstrąg
              </button>
            </li>
          </ul>

          {this.state.dorsz && <Dorsz />}
          {this.state.sledz && <Sledz />}
          {this.state.losos && <Losos />}
          {this.state.szprot && <Szprot />}
          {this.state.stornia && <Stornia />}
          {this.state.okon && <Okon />}
          {this.state.ploc && <Ploc />}
          {this.state.sandacz && <Sandacz />}
          {this.state.leszcz && <Leszcz />}
          {this.state.wegorz && <Wegorz />}
          {this.state.karp && <Karp />}
          {this.state.pstrag && <Pstrag />}
        </div>

        <ZielonyPas napis="&copy; Morski Instytut Rybacki - Państwowy Instytut Badawczy." />
      </div>
    );
  }
}

export default StronaGlowna;
