import React, { Component } from 'react';
// import Chart from './Chart';
import { Bar } from 'react-chartjs-2';

export class DioksynyData extends Component {
  constructor() {
    super();
    this.state = {
      chartData: {},
      waga: 70,
      zapotrzebowanie: 14 * 70,
      porcjaGram: 100,
      displayTitle: true,
      displayLegend: true,
      legendPosition: 'bottom',
      tytul: 'Zawartość dioksyn w poszczególnych gatunkach ryb'
    };
  }

  componentDidMount() {
    this.getChartData();
  }

  getChartData() {
    // Ajax calls here
    this.setState({
      chartData: {
        labels: [
            'dorsz',
            'śledź',
            'łosoś bałtycki',
            'szprot',
            'stornia',
            'okoń',
            'karp',
            'pstrąg'
        ],
        datasets: [
          {
            label: 'dioksyny [pikogram] [WHO-TEQ]',
            data: [
              Math.round(1.05 * this.state.porcjaGram * 100) / 100,
              Math.round(2.5 * this.state.porcjaGram * 100) / 100,
              Math.round(9.44 * this.state.porcjaGram * 100) / 100,
              Math.round(4.97 * this.state.porcjaGram * 100) / 100,
              Math.round(0.723 * this.state.porcjaGram * 100) / 100,
              Math.round(0.194 * this.state.porcjaGram * 100) / 100,
              Math.round(0.14 * this.state.porcjaGram * 100) / 100,
              Math.round(0.39 * this.state.porcjaGram * 100) / 100
            ],
            backgroundColor: [
                'rgba(255, 242, 0, 0.6)',
                'rgba(253, 116, 3, 0.6)',
                'rgba(252, 163, 4, 0.6)',
                'rgba(0, 0, 255, 0.6)',
                'rgba(0, 100, 0, 0.6)',
                'rgba(146, 208, 80, 0.6)',
                'rgba(112, 48, 160, 0.6)',
                'rgba(233, 9, 238, 0.6)'
            ]
          },
          {
            label: 'dopuszczalne tygodniowe pobranie dioksyn',
            data: [
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie
            ],
            type: 'line'
          }
        ]
      }
    });
  }

  handleChangeWaga = event => {
    this.setState({
      waga: event.target.value,
      // zapotrzebowanie: event.target.value * 0.0094
      zapotrzebowanie: Math.round(event.target.value * 14 * 100) / 100
    });
  };

  handlePorcjaGram = event => {
    this.setState({
      porcjaGram: event.target.value
    });
  };

  handleSubmit = event => {
    // alert(`${this.state.zapotrzebowanie}`);
    this.setState({
      chartData: {
        labels: [
            'dorsz',
            'śledź',
            'łosoś bałtycki',
            'szprot',
            'stornia',
            'okoń',
            'karp',
            'pstrąg'
        ],
        datasets: [
          {
            label: 'dikoksyny [pikogramy]',
            data: [
                Math.round(1.05 * this.state.porcjaGram * 100) / 100,
                Math.round(2.5 * this.state.porcjaGram * 100) / 100,
                Math.round(9.44 * this.state.porcjaGram * 100) / 100,
                Math.round(4.97 * this.state.porcjaGram * 100) / 100,
                Math.round(0.723 * this.state.porcjaGram * 100) / 100,
                Math.round(0.194 * this.state.porcjaGram * 100) / 100,
                Math.round(0.14 * this.state.porcjaGram * 100) / 100,
                Math.round(0.39 * this.state.porcjaGram * 100) / 100
            ],
            backgroundColor: [
                'rgba(255, 242, 0, 0.6)',
                'rgba(253, 116, 3, 0.6)',
                'rgba(252, 163, 4, 0.6)',
                'rgba(0, 0, 255, 0.6)',
                'rgba(0, 100, 0, 0.6)',
                'rgba(146, 208, 80, 0.6)',
                'rgba(112, 48, 160, 0.6)',
                'rgba(233, 9, 238, 0.6)'
            ]
          },
          {
            label: 'dopuszczalne tygodniowe pobranie dioksyn',
            data: [
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie
            ],
            type: 'line'
          }
        ]
      }
    });
    event.preventDefault();
  };

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              marginTop: '20px'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <label>Twoja waga (kg)</label>
              <input
                type="text"
                value={this.state.waga}
                onChange={this.handleChangeWaga}
                style={{ width: '200px', textAlign: 'center' }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <label>Porcja ryby (gramy)</label>
              <input
                type="text"
                value={this.state.porcjaGram}
                onChange={this.handlePorcjaGram}
                style={{ width: '200px', textAlign: 'center' }}
              />
            </div>
          </div>
          <br />
          <button type="submit" className="btn btn-success">
            Przelicz
          </button>
        </form>
        {/* <Chart chartData={this.state.chartData} tytul="Zawartość lizyny w poszczególnych gatunkach ryb" legendPosition="bottom"/>   */}
        <div className="chart">
          <Bar
            data={this.state.chartData}
            options={{
              title: {
                display: this.state.displayTitle,
                text: this.state.tytul,
                fontSize: 25
              },
              legend: {
                display: this.state.displayLegend,
                position: this.state.legendPosition
              },
              maintainAspectRatio: true,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      min: 0
                    }
                  }
                ]
              }
            }}
          />
        </div>
      </div>
    );
  }
}

export default DioksynyData;
