import React, { Component } from 'react';
// import { BrowserRouter, Route } from 'react-router-dom';
import LizynaData from './LizynaData';
import BialkoData from './BialkoData';
import TluszczData from './TluszczData';
import KwTluszczoweData from './KwTluszczoweData';
import DioksynyData from './Dioksyny';
import D3Data from './D3Data';
import { EData } from './EData';
import KadmData from './KadmData';
import OlowData from './OlowData';
import MeHgData from './MeHgData';

class Kalkulator extends Component {
  constructor() {
    super();
    this.state = {
      lizyna: true,
      bialko: false,
      tluszcz: false,
      kwTluszczowe: false,
      dioksyny: false,
      d3: false,
      witE: false,
      kadm: false,
      olow: false,
      metylortec: false
    };
  }

  handleCalcType = event => {
    // console.log(event.target.value);
    const kalkulator = event.target.value;

    switch (kalkulator) {
      case 'lizyna':
        this.setState({
          lizyna: true,
          bialko: false,
          tluszcz: false,
          kwTluszczowe: false,
          dioksyny: false,
          d3: false,
          witE: false,
          kadm: false,
          olow: false,
          metylortec: false
        });
        break;
      case 'bialko':
        this.setState({
          lizyna: false,
          bialko: true,
          tluszcz: false,
          kwTluszczowe: false,
          dioksyny: false,
          d3: false,
          witE: false,
          kadm: false,
          olow: false,
          metylortec: false
        });
        break;
      case 'tluszcz':
        this.setState({
          lizyna: false,
          bialko: false,
          tluszcz: true,
          kwTluszczowe: false,
          dioksyny: false,
          d3: false,
          witE: false,
          kadm: false,
          olow: false,
          metylortec: false
        });
        break;
      case 'kwTluszczowe':
        this.setState({
          lizyna: false,
          bialko: false,
          tluszcz: false,
          kwTluszczowe: true,
          dioksyny: false,
          d3: false,
          witE: false,
          kadm: false,
          olow: false,
          metylortec: false
        });
        break;
      case 'dioksyny':
        this.setState({
          lizyna: false,
          bialko: false,
          tluszcz: false,
          kwTluszczowe: false,
          dioksyny: true,
          d3: false,
          witE: false,
          kadm: false,
          olow: false,
          metylortec: false
        });
        break;
      case 'd3':
        this.setState({
          lizyna: false,
          bialko: false,
          tluszcz: false,
          kwTluszczowe: false,
          dioksyny: false,
          d3: true,
          witE: false,
          kadm: false,
          olow: false,
          metylortec: false
        });
        break;
      case 'witE':
        this.setState({
          lizyna: false,
          bialko: false,
          tluszcz: false,
          kwTluszczowe: false,
          dioksyny: false,
          d3: false,
          witE: true,
          kadm: false,
          olow: false,
          metylortec: false
        });
        break;
      case 'kadm':
        this.setState({
          lizyna: false,
          bialko: false,
          tluszcz: false,
          kwTluszczowe: false,
          dioksyny: false,
          d3: false,
          witE: false,
          kadm: true,
          olow: false,
          metylortec: false
        });
        break;
      case 'olow':
        this.setState({
          lizyna: false,
          bialko: false,
          tluszcz: false,
          kwTluszczowe: false,
          dioksyny: false,
          d3: false,
          witE: false,
          kadm: false,
          olow: true,
          metylortec: false
        });
        break;
      case 'metylortec':
        this.setState({
          lizyna: false,
          bialko: false,
          tluszcz: false,
          kwTluszczowe: false,
          dioksyny: false,
          d3: false,
          witE: false,
          kadm: false,
          olow: false,
          metylortec: true
        });
        break;
      default:
        this.setState({
          lizyna: true,
          bialko: false,
          tluszcz: false,
          kwTluszczowe: false,
          dioksyny: false,
          d3: false,
          witE: false,
          kadm: false,
          olow: false,
          metylortec: false
        });
    }

    // this.setState({
    //   lizyna: !this.state.lizyna
    // });
  };

  render() {
    return (
      <div className="container" style={{ marginTop: '20px' }}>
        <ul
          className="nav"
          style={{
            marginBottom: '10px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <li className="nav-item">
            <button
              className="btn btn btn-info"
              onClick={this.handleCalcType}
              value="lizyna"
            >
              Lizyna
            </button>
          </li>

          <li className="nav-item">
            <button
              className="btn btn btn-info"
              onClick={this.handleCalcType}
              value="bialko"
            >
              Białko
            </button>
          </li>

          <li className="nav-item">
            <button
              className="btn btn btn-info"
              onClick={this.handleCalcType}
              value="tluszcz"
            >
              Tłuszcz
            </button>
          </li>

          <li className="nav-item">
            <button
              className="btn btn btn-info"
              onClick={this.handleCalcType}
              value="kwTluszczowe"
            >
              Kwasy Tłuszczowe
            </button>
          </li>

          <li className="nav-item">
            <button
              className="btn btn btn-info"
              onClick={this.handleCalcType}
              value="d3"
            >
              Witamina D<sub>3</sub>
            </button>
          </li>

          <li className="nav-item">
            <button
              className="btn btn btn-info"
              onClick={this.handleCalcType}
              value="witE"
            >
              Witamina E
            </button>
          </li>
        </ul>

        <ul
          className="nav"
          style={{
            marginBottom: '40px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <li className="nav-item">
            <button
              className="btn btn btn-info"
              onClick={this.handleCalcType}
              value="dioksyny"
            >
              Dioksyny
            </button>
          </li>

          <li className="nav-item">
            <button
              className="btn btn btn-info"
              onClick={this.handleCalcType}
              value="kadm"
            >
              Metale - Kadm (Cd)
            </button>
          </li>

          <li className="nav-item">
            <button
              className="btn btn btn-info"
              onClick={this.handleCalcType}
              value="olow"
            >
              Metale - Ołów (Pb)
            </button>
          </li>

          <li className="nav-item">
            <button
              className="btn btn btn-info"
              onClick={this.handleCalcType}
              value="metylortec"
            >
              Metale - "Me-Hg"
            </button>
          </li>
        </ul>

        {this.state.lizyna && <LizynaData />}
        {this.state.bialko && <BialkoData />}
        {this.state.tluszcz && <TluszczData />}
        {this.state.kwTluszczowe && <KwTluszczoweData />}
        {this.state.dioksyny && <DioksynyData />}
        {this.state.d3 && <D3Data />}
        {this.state.witE && <EData />}
        {this.state.kadm && <KadmData />}
        {this.state.olow && <OlowData />}
        {this.state.metylortec && <MeHgData />}
      </div>
    );
  }
}

export default Kalkulator;
