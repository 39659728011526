import React, { Component } from 'react';
// import Chart from './Chart';
import { Bar } from 'react-chartjs-2';

export class TluszczData extends Component {
  constructor() {
    super();
    this.state = {
      chartData: {},
      waga: 70,
      mezczyzna: true,
      kobieta: false,
      zapotrzebowanie: 80,
      porcjaGram: 100,
      displayTitle: true,
      displayLegend: true,
      kobMen: 'mężczyźni',
      legendPosition: 'bottom',
      tytul: 'Zawartość tłuszczu w poszczególnych gatunkach ryb'
    };
  }

  componentDidMount() {
    this.getChartData();
  }

  getChartData() {
    // Ajax calls here
    if (this.state.mezczyzna) {
      this.setState({
        zapotrzebowanie: 80,
        kobMen: 'mężczyźni'
      });
    } else {
      this.setState({
        zapotrzebowanie: 70,
        kobMen: 'kobiety'
      });
    }
    this.setState({
      chartData: {
        labels: [
          'dorsz',
          'śledź',
          'łosoś bałtycki',
          'okoń',
          'płoć',
          'sandacz',
          'leszcz',
          'węgorz',
          'karp',
          'pstrąg'
        ],
        datasets: [
          {
            label: 'gram tłuszczu',
            data: [
              Math.round(0.0008 * this.state.porcjaGram * 100) / 100,
              Math.round(0.037 * this.state.porcjaGram * 100) / 100,
              Math.round(0.131 * this.state.porcjaGram * 100) / 100,
              Math.round(0.0012 * this.state.porcjaGram * 100) / 100,
              Math.round(0.0056 * this.state.porcjaGram * 100) / 100,
              Math.round(0.0013 * this.state.porcjaGram * 100) / 100,
              Math.round(0.0259 * this.state.porcjaGram * 100) / 100,
              Math.round(0.289 * this.state.porcjaGram * 100) / 100,
              Math.round(0.051 * this.state.porcjaGram * 100) / 100,
              Math.round(0.074 * this.state.porcjaGram * 100) / 100
            ],
            backgroundColor: [
              'rgba(255, 242, 0, 0.6)',
              'rgba(253, 116, 3, 0.6)',
              'rgba(252, 163, 4, 0.6)',
              'rgba(146, 208, 80, 0.6)',
              'rgba(0, 176, 80, 0.6)',
              'rgba(0, 176, 240, 0.6)',
              'rgba(0, 112, 192, 0.6)',
              'rgba(0, 32, 96, 0.6)',
              'rgba(112, 48, 160, 0.6)',
              'rgba(233, 9, 238, 0.6)'
            ]
          },
          {
            label: 'dzienne zapotrzebowanie - ' + this.state.kobMen,
            data: [
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie
            ],
            type: 'line'
          }
        ]
      }
    });
  }

  handleChangeWaga = event => {
    this.setState({
      waga: event.target.value,
      // zapotrzebowanie: event.target.value * 0.0094
      zapotrzebowanie: Math.round(event.target.value * 0.9 * 100) / 100
    });
  };

  handlePorcjaGram = event => {
    this.setState({
      porcjaGram: event.target.value
    });
  };

  handleSubmit = event => {
    // alert(`${this.state.zapotrzebowanie}`);
    this.setState({
      chartData: {
        labels: [
          'dorsz',
          'śledź',
          'łosoś bałtycki',
          'okoń',
          'płoć',
          'sandacz',
          'leszcz',
          'węgorz',
          'karp',
          'pstrąg'
        ],
        datasets: [
          {
            label: 'gram tłuszczu',
            data: [
              Math.round(0.0008 * this.state.porcjaGram * 100) / 100,
              Math.round(0.037 * this.state.porcjaGram * 100) / 100,
              Math.round(0.131 * this.state.porcjaGram * 100) / 100,
              Math.round(0.0012 * this.state.porcjaGram * 100) / 100,
              Math.round(0.0056 * this.state.porcjaGram * 100) / 100,
              Math.round(0.0013 * this.state.porcjaGram * 100) / 100,
              Math.round(0.0259 * this.state.porcjaGram * 100) / 100,
              Math.round(0.289 * this.state.porcjaGram * 100) / 100,
              Math.round(0.051 * this.state.porcjaGram * 100) / 100,
              Math.round(0.074 * this.state.porcjaGram * 100) / 100
            ],
            backgroundColor: [
              'rgba(255, 242, 0, 0.6)',
              'rgba(253, 116, 3, 0.6)',
              'rgba(252, 163, 4, 0.6)',
              'rgba(146, 208, 80, 0.6)',
              'rgba(0, 176, 80, 0.6)',
              'rgba(0, 176, 240, 0.6)',
              'rgba(0, 112, 192, 0.6)',
              'rgba(0, 32, 96, 0.6)',
              'rgba(112, 48, 160, 0.6)',
              'rgba(233, 9, 238, 0.6)'
            ]
          },
          {
            label: 'dzienne zapotrzebowanie - ' + this.state.kobMen,
            data: [
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie,
              this.state.zapotrzebowanie
            ],
            type: 'line'
          }
        ]
      }
    });
    event.preventDefault();
  };

  handleOptionChange = changeEvent => {
    // console.log(changeEvent.target.value);
    this.setState({
      mezczyzna: !this.state.mezczyzna,
      kobieta: !this.state.kobieta
    });
    if (changeEvent.target.value === 'mezczyzna') {
      this.setState({
        zapotrzebowanie: 80,
        kobMen: 'mężczyźni'
      });
    }
    if (changeEvent.target.value === 'kobieta') {
      this.setState({
        zapotrzebowanie: 70,
        kobMen: 'kobiety'
      });
    }
  };

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              marginTop: '20px'
            }}
          >
              <label>Jestem</label>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: "20px" }}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  value="mezczyzna"
                  checked={this.state.mezczyzna}
                  onChange={this.handleOptionChange}
                />
                <label className="form-check-label" htmlFor="exampleRadios1">
                  mężczyzną
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  value="kobieta"
                  onChange={this.handleOptionChange}
                />
                <label className="form-check-label" htmlFor="exampleRadios2">
                  kobietą
                </label>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <label>Porcja ryby (gramy)</label>
              <input
                type="text"
                value={this.state.porcjaGram}
                onChange={this.handlePorcjaGram}
                style={{ width: '200px', textAlign: 'center' }}
              />
            </div>
          </div>
          <br />
          <button type="submit" className="btn btn-success">
            Przelicz
          </button>
        </form>
        {/* <Chart chartData={this.state.chartData} tytul="Zawartość lizyny w poszczególnych gatunkach ryb" legendPosition="bottom"/>   */}
        <div className="chart">
          <Bar
            data={this.state.chartData}
            options={{
              title: {
                display: this.state.displayTitle,
                text: this.state.tytul,
                fontSize: 25
              },
              legend: {
                display: this.state.displayLegend,
                position: this.state.legendPosition
              },
              maintainAspectRatio: true,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      min: 0
                    }
                  }
                ]
              }
            }}
          />
        </div>
      </div>
    );
  }
}

export default TluszczData;
