import React from 'react';
import ModalImage from 'react-modal-image';
import './Dorsz.css';


function Sandacz() {
    return (
        <div className="glowna">
        <h4>SANDACZ</h4>
  
        <ModalImage
          small="./galeria/sandacz1_250.jpg"
          large="./galeria/sandacz1_1000.jpg"
          alt="sandacz"
        />
  
        <p>
        Sandacz (Sander lucioperca) to gatunek ryby z rodziny okoniowatych, występujący w jeziorach, zbiornikach zaporowych, średnich i dużych nizinnych rzekach, wyrobiskach oraz w płytkich wodach przybrzeżnych Bałtyku. Sandacz to jedna z najsmaczniejszych i najchętniej spożywanych ryb. Jego mięso jest jasne, delikatne, niezbyt ścisłe i – co jest wielką zaletą  - zawiera stosunkowo niewiele ości. Sandacz należy do ryb o niskiej kaloryczności (niecałe 80 kcal/100 g) i niewielkiej zawartości tłuszczu (0,06-0,42%), a co za tym idzie  - cennych kwasów tłuszczowych. Jego mięso jest za to doskonałym źródłem łatwo przyswajalnego białka (aż 19,5 g w 100 g), bogatego w cenny aminokwas egzogenny – lizynę, a także minerałów takich jak jod, fosfor, selen i fluor.
        </p>
  
        <div className="obrazki">
          <ModalImage
            className="ramka"
            small="./galeria/sandacz2_250.jpg"
            large="./galeria/sandacz2_1000.jpg"
            alt="smażony sandacz"
          />
  
          <ModalImage
            className="ramka"
            small="./galeria/sandacz3_250.jpg"
            large="./galeria/sandacz3_1000.jpg"
            alt="Smacznego!"
          />
  
          <ModalImage
            className="ramka"
            small="./galeria/sandacz4_250.jpg"
            large="./galeria/sandacz4_1000.jpg"
            alt="sandacz - filety"
          />
        </div>
      </div>
    )
}

export default Sandacz
